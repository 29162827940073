<template>
<section class="py_100">
  <div class="d-flex align-items-center justify-content-center h-100 py_100">
    <div class="page_not_found text-center h-100 w-50 d-none">
        <h1 class="text-danger">SSO Login</h1>
        <h2 class="m-4 text-uppercase">Please Wait! </h2>
        <div class="separator  transparent center  " style="margin-top:35px;"></div>
           
      </div>
  </div>
  </section>
</template>

<script> 
export default {
  name: "sso-error",
  mounted(){ 
    if(this.$route.params.error){
       this.$router.push({name:"login",params: { error: this.$route.params.error }});
    }    
  },
};
</script>
